.have-an-idea {
  margin: 50px auto;
  max-width: 30em;
  text-align: center;
  padding: 0 20px;
}

.have-an-idea p {
  font-size: 1.2em;
}

.have-an-idea__button {
  font-family: var(--runway-font);
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 2em;
  background-color: transparent;
  padding: 14px 60px 7px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
  text-decoration: none;
}

.have-an-idea__button:hover {
  background-color: var(--primary-color);
  color: white;
}
