.projects h1 {
  font-family: var(--runway-font);
  font-size: 3em;
  font-weight: 400;
  text-align: center;
  margin: 2em 0;
}

.projects ul {
  list-style-type: none;
  padding: 0;
}

.projects-item {
  position: relative;
  height: 300px;
  margin: 20px;
}

.projects-item-image {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.projects-item-description {
  align-items: baseline;
  display: flex;
  font-size: 0.9em;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  color: white;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  padding: 6px 8px;
  box-sizing: border-box; /* Para asegurarnos de que el padding no afecte el ancho */
}

.projects-item-description h3 {
  font-size: 1em;
  margin: 0;
}

.projects-item-description__category {
  margin: 0;
  text-align: right;
}

.projects-item-description__category:not(:last-child)::after {
  content: ", ";
}

.slide-in-blurred-top {
  animation: slide-in-blurred-top 1.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s both;
}

.truncated-categories {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 225px;
}

@keyframes slide-in-blurred-top {
  0% {
    transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 0%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .projects {
    max-width: 872px;
    margin: 1em auto;
  }

  .projects ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .projects-item {
    height: 290px;
    width: 420px;
    margin: 0 1em 1em 0;
    transition: transform 0.3s;
  }

  .projects-item:hover {
    transform: scale(1.01);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }

  .projects-item-image {
    height: 290px;
    width: 420px;
    object-fit: cover;
  }
}

@media (min-width: 870px) {
  .projects {
    text-align: left;
  }
  .projects ul {
    justify-content: start;
  }
}
