@font-face {
  font-family: "RunwayBlend"; /* Nombre de la fuente */
  src: url("./assets/fonts/Runway-Blend.woff2") format("woff2"),
    url("./assets/fonts/Runway-Blend.woff") format("woff"),
    url("./assets/fonts/Runway-Blend.otf") format("opentype"); /* OTF como fallback */
  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #0000ff;
  --secondary-color: #f6f3eb;
  --runway-font: "RunwayBlend", sans-serif;
}

body,
html,
#root {
  background-color: #f6f3eb;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  color: var(--primary-color);
  -webkit-overflow-scrolling: touch;
  /* cursor: url("./assets/icons/RocketIcon.svg"), auto; */
}

/* Barra de scroll personalizada */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #0000ff;
  border-radius: 5px;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.main-content {
  margin-top: 64px;
  flex: 1;
}

.app-container,
.main-content {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lexmake-button {
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1.1em;
  background-color: transparent;
  padding: 8px 15px;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.lexmake-button:hover {
  background-color: var(--primary-color);
  color: white;
}

h1 {
  font-family: var(--runway-font);
  font-weight: 400;
  font-size: 2.5em;
}

/* Cursor Personalizado */
/* a,
button,
[role="button"] {
  cursor: url("./assets/icons/rocket-simple.svg"), pointer;
} */

@media (min-width: 768px) {
  .main-content {
    margin-top: 0;
  }
}
