.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 11; /* Asegúrate de que el botón esté por encima del menú */
}

.menu-button__line {
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-button__line.open:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.menu-button__line.open:nth-child(2) {
  opacity: 0;
}

.menu-button__line.open:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}
