/* CheckoutModal.css */
.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(
    0,
    0,
    255,
    0.1
  ); /* Usando un tono del color primario */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 1000;
}

.modal-container {
  background-color: var(--secondary-color);
  border-radius: 1rem;
  width: 100%;
  cursor: default;
  max-width: 500px;
  padding: 2rem;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 255, 0.1);
  color: var(--primary-color);
  font-family: Manrope, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

.modal-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  padding: 0;
  line-height: 1;
}

.modal-close:hover {
  transform: rotate(90deg);
}

.modal-title {
  font-family: var(--runway-font);
  font-weight: 400;
  font-size: 2em;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.modal-content {
  margin-bottom: 2rem;
}

.modal-description {
  color: var(--primary-color);
  margin-bottom: 2rem;
  line-height: 1.6;
  font-size: 1.1em;
}

.services-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.service-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  color: var(--primary-color);
  font-size: 1.1em;
}

.service-dot {
  width: 8px;
  height: 8px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 0.75rem;
  flex-shrink: 0;
}

.modal-actions {
  display: flex;
  gap: 1.5rem;
  margin-top: 2rem;
}

.button {
  flex: 1;
  padding: 0.75rem 1.5rem;
  font-size: 1.1em;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: inherit;
}

.button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-cancel {
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  background-color: transparent;
  color: var(--primary-color);
}

.button-cancel:hover:not(:disabled) {
  background-color: var(--primary-color);
  color: white;
}

.button-confirm {
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  background-color: var(--primary-color);
  color: white;
}

.button-confirm:hover:not(:disabled) {
  background-color: transparent;
  color: var(--primary-color);
}

@media (max-width: 480px) {
  .modal-container {
    margin: 1rem;
    padding: 1.5rem;
  }

  .modal-actions {
    flex-direction: column;
  }

  .button {
    width: 100%;
  }

  .modal-title {
    font-size: 1.8em;
  }
}
