.home-skills {
  display: flex;
  flex-direction: column;
  margin: 30px auto;
  max-width: 22em;
  padding: 20px;
}

.home-skills h1 {
  margin-bottom: 0;
}

.home-skills__items-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: space-between;
  gap: 15px;
}

.home-skills__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0.5em;
  position: relative;
  cursor: pointer;
}

.home-skills__item img {
  width: 3.5em;
  transform: rotate(0deg);
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.home-skills__item.rotating img {
  transform: rotate(360deg);
}

.home-skills__name {
  position: absolute;
  top: -40px;
  background: #0000ff;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.3s ease;
  pointer-events: none;
  white-space: nowrap;
}

.home-skills__name::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #0000ff;
}

.home-skills__item.active .home-skills__name {
  opacity: 1;
  transform: translateY(0);
}

.home-skills__item:hover img {
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .home-skills {
    margin: 100px auto;
    padding: 0;
    max-width: 50em;
  }

  .home-skills__items-container {
    margin: 30px 0;
  }
}
