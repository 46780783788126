.home-hero {
  display: flex;
  flex-direction: column;
  padding: 130px 20px;
}

.home-hero__logo {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 20em;
  padding-right: 30px; /* Compensa el espacio del cohete */
}

.home-hero__text {
  width: 100%;
}

.home-hero__rocket {
  position: absolute;
  width: 3em;
  top: -30px;
  right: -30px;
}

.home-hero__description {
  margin: auto;
  margin-top: -20px;
  max-width: 21em;
  overflow: hidden;
}

@media (max-width: 768px) {
  .home-hero__logo {
    padding-right: 0;
    transform: translateX(
      -15px
    ); /* Mueve todo el conjunto hacia la izquierda para centrarlo */
  }
}

@media (min-width: 768px) {
  .home-hero__logo {
    max-width: 35em;
  }

  .home-hero__rocket {
    width: 4em;
  }

  .home-hero__description {
    margin-top: -15px;
    max-width: 38em;
    padding-left: 50px; /* Compensa el espacio del cohete */
  }
}
