.home-projects {
  margin: 25px 0;
}

.home-projects__item {
  height: 300px;
  position: relative;
  margin: 20px;
}

.home-projects__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.home-projects__item-description {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  color: white;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  padding: 8px 10px;
  box-sizing: border-box; /* Para asegurarnos de que el padding no afecte el ancho */
}

.home-projects__item-description h3 {
  font-size: 0.9em;
  margin: 0;
}

.home-projects__item-description p {
  font-size: 0.8em;
  margin: 0;
  text-align: right;
}

.home-projects__item:last-child {
  display: grid;
  place-items: center;
}

.home-projects__item:last-child a {
  color: var(--primary-color);
  text-decoration: none;
}

@media (min-width: 768px) {
  .home-projects {
    margin: 25px 0 200px;
  }

  .home-projects__container {
    display: flex;
  }

  .home-projects__item {
    flex-shrink: 0;
    height: 440px;
    margin: 0;
    width: 580px;
  }
}
