.shop-cart {
  display: flex;
  flex-direction: column;
  max-width: 50em;
  margin: auto;
  align-items: center;
  margin-bottom: 150px;
  cursor: url("../../../assets/icons/hand-icon.svg"), auto;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1rem;
  gap: 0.75rem;
  justify-content: center;
}

.draggable-tag {
  border: 1px solid black;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  transition: box-shadow 0.2s ease;
  color: black;
}

.draggable-tag:hover {
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
}

.bag-and-ticket {
  margin-top: 5rem;
  display: flex;
  gap: 6rem;
  align-items: flex-start;
}

.bag-area {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.bag-image {
  width: 330px;
  height: auto;
  margin: 0 auto;
  display: block;
  pointer-events: none; /* Asegura que la imagen no interfiera con el drop */
}

.bag-content {
  text-align: center;
}

.bag-content p {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #6b7280;
}

.ticket {
  cursor: default;
  background: white;
  width: 250px;
  padding: 2rem;
  position: relative;
  /* Quitamos el border-radius anterior y la shadow */
}

/* Creamos el borde irregular superior */
.ticket::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='250' height='8' viewBox='0 0 250 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8C25 8 25 0 50 0C75 0 75 8 100 8C125 8 125 0 150 0C175 0 175 8 200 8C225 8 225 0 250 0V8H0Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Creamos el borde irregular inferior */
.ticket::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  height: 8px;
  background-image: url("data:image/svg+xml,%3Csvg width='250' height='8' viewBox='0 0 250 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0C25 0 25 8 50 8C75 8 75 0 100 0C125 0 125 8 150 8C175 8 175 0 200 0C225 0 225 8 250 8V0H0Z' fill='white'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

/* Añadimos líneas punteadas como separadores */
.ticket-header {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.ticket-header h3 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: black;
}

.ticket-items {
  border-bottom: 1px dashed #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.ticket-item {
  color: black;
  font-size: 0.875rem;
}

.finish-button {
  width: 100%;
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 50px;
  padding: 0.5rem 0 0.2rem;
  margin-top: 1.5rem;
  cursor: pointer;
  font-family: var(--runway-font);
  font-size: 18px;
  transition: background-color 0.2s ease;
}

.finish-button:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 768px) {
  .shop-cart {
    display: none;
  }
}
