/* Estilos base (móvil primero) */
.about-container {
  max-width: 870px;
  margin: 0 auto;
  padding: 20px;
}

.about-container p {
  text-align: justify;
}

.about-title {
  font-size: 2.2rem;
  margin-bottom: 30px;
}

.about-top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.about-image-container {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.about-image-background {
  position: absolute;
  width: 119%;
  height: 130%;
  top: -15%;
  left: -15%;
  background: radial-gradient(
    circle at center,
    rgba(0, 0, 255, 0.3) 0%,
    rgba(0, 0, 255, 0.4) 10%,
    rgba(255, 255, 255, 0) 60%
  );
  z-index: -1;
  pointer-events: none;
  transform: scale(0);
  opacity: 0;
  display: block;
  transform-origin: center;
  will-change: transform, opacity;
  z-index: 1;
}

.about-alexia-image {
  position: relative;
  width: 100%;
  height: auto;
  max-height: 350px;
  object-fit: contain;
  z-index: 2;
}

.about-text-primary {
  color: black;
  margin-bottom: 30px;
}

.about-text-primary p {
  max-width: 670px;
}

.about-text-primary .highlight {
  color: var(--primary-color);
}

.blue-line-mobile {
  display: block;
  height: 12px;
  background-color: var(--primary-color);
  margin-top: -35px;
  position: relative;
  z-index: 2;
  width: 0;
  transform-origin: left;
}

.about-bottom-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about-text-secondary {
  width: 100%;
  margin: 0;
  color: black;
}

.quote-section {
  width: 100%;
}

.quote-container {
  position: relative;
  padding: 20px;
}

.quote-text {
  font-family: var(--runway-font);
  font-size: 1.4rem;
  color: var(--primary-color);
  position: relative;
  z-index: 2;
}

.quote-mark {
  position: absolute;
  opacity: 0.5;
  width: 60px;
  height: 60px;
}

.quote-mark-left {
  top: -10px;
  left: -20px;
}

.quote-mark-right {
  bottom: 0;
  right: 10px;
  transform: rotate(180deg);
}

/* Estilos desktop */
@media (min-width: 768px) {
  .about-container {
    padding: 40px 20px;
  }

  .about-container p {
    text-align: left;
  }

  .about-title {
    font-size: 3rem;
  }

  .about-top-section {
    flex-direction: row;
    margin: 0 30px;
  }

  .about-image-container {
    z-index: 1;
  }

  .about-alexia-image {
    height: 440px;
    width: auto;
  }

  .blue-line-mobile {
    display: none;
  }

  .blue-line {
    display: block;
    height: 12px;
    background-color: var(--primary-color);
    margin-top: -35px;
    position: relative;
    z-index: 2;
    width: 0;
    transform-origin: left;
  }

  .about-bottom-section {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin: 50px 20px;
  }

  .about-text-secondary {
    width: 60%;
    margin: 20px 10px;
  }

  .about-text-secondary p {
    max-width: 350px;
  }

  .quote-section {
    width: 40%;
  }

  .quote-text {
    font-size: 1.8rem;
  }

  .quote-mark {
    width: 90px;
    height: 90px;
  }

  .quote-mark-left {
    top: -15px;
    left: -45px;
  }

  .quote-mark-right {
    bottom: 0;
    right: 20px;
  }
}
