.header {
  width: 100%;
  z-index: 100;
}

/* Estilos móvil */
.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
}

.desktop-header {
  display: none;
}

.nav-links {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #f6f3eb;
  transition: right 0.5s ease-out;
  z-index: 9;
  padding-top: 70px;
}

.logo svg {
  width: 43px;
}

.nav-links.open {
  right: 0;
}

.nav-links a {
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: #333;
  border-bottom: 1px solid #ddd;
  font-size: 1.5rem;
}

/* Estilos desktop */
@media (min-width: 768px) {
  .mobile-header {
    display: none;
  }

  .desktop-header {
    display: flex;
    justify-content: center; /* Centramos el contenedor */
    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    height: 70px;
  }

  .desktop-header-container {
    max-width: 1100px;
    width: 100%;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    flex: 1;
  }

  .logo-container {
    margin-left: 90px;
  }

  .desktop-nav {
    flex: 2;
    display: flex;
    justify-content: center;
    gap: 0;
    position: relative;
  }

  .desktop-nav a {
    text-decoration: none;
    color: #000;
    font-size: 1rem;
    transition: all 0.3s ease;
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center; /* Centramos el texto horizontalmente */
    width: 150px; /* Ancho fijo para cada tab */
  }

  .desktop-nav a::after {
    content: "";
    position: absolute;
    right: 0; /* Ahora el separador está justo al final del elemento */
    top: 50%;
    transform: translateY(-50%);
    height: 70px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .desktop-nav a:first-child::before {
    content: "";
    position: absolute;
    left: 0; /* Ahora el separador está justo al inicio del elemento */
    top: 50%;
    transform: translateY(-50%);
    height: 70px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .desktop-nav a:hover {
    opacity: 0.7;
  }

  .desktop-nav a.active {
    background-color: #000;
    color: white;
  }

  .contact-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }

  .contact-section a {
    color: #000;
  }

  /* Estilos para el contenedor de email y redes sociales */
  .contact-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px; /* Aumentado el espacio entre el email y los iconos */
  }

  .contact-section .email {
    font-size: 0.9rem;
    line-height: 1; /* Asegura que el email tenga una altura consistente */
  }

  /* Estilos para los iconos sociales */
  .social-icons {
    display: flex;
    gap: 16px; /* Aumentado el espacio entre iconos */
    align-items: center;
  }

  .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    transition: opacity 0.3s ease;
    width: 20px; /* Ancho fijo para el contenedor del icono */
    height: 20px; /* Altura fija para el contenedor del icono */
  }

  .social-icon svg {
    width: 18px; /* Tamaño fijo para todos los SVG */
    height: 18px; /* Tamaño fijo para todos los SVG */
  }

  .social-icon--instagram {
    height: 15px;
    width: 15px;
  }

  .social-icon--linkedin {
    height: 14px;
    width: 14px;
  }

  .social-icon--whatsapp {
    height: 16px;
    width: 16px;
  }

  .menu-button {
    display: none;
  }
}
