.image-container {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.skeleton {
  position: absolute;
  inset: 0;
  background: linear-gradient(
        90deg,
        transparent 25%,
        rgba(255, 255, 255, 0.3) 50%,
        transparent 75%
      )
      0 0 / 200% 100%,
    #e2e2e2
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23d4d4d4' d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14zm-5-7l-3 3.72L9 13l-3 4h12l-4-5z'/%3E%3C/svg%3E")
      center / 30% no-repeat;
  animation: pulse 2s infinite linear;
}

.image-hidden {
  opacity: 0;
}

.image-visible {
  opacity: 1;
  transition: opacity 0.3s;
}

img {
  width: 100%;
  height: 100%;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
