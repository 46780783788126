.contact {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
}

.contact h1 {
  text-align: center;
  text-transform: uppercase;
  margin: 2em 0;
}

.contact form {
  display: flex;
  flex-direction: column;
}

.contact__tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.contact__tabs button {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid var(--primary-color);
  border-radius: 60px;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-color);
}

.contact__tabs button.active {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
}

.contact__tabs button:not(:last-child) {
  margin-right: 5px;
}

.contact input,
.contact textarea {
  font-family: Manrope;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid var(--primary-color); /* Solo borde inferior */
  background-color: transparent;
  color: var(--primary-color);
  outline: none;
  transition: border-color 0.3s;
}

.contact input::placeholder,
.contact textarea::placeholder {
  color: var(--primary-color);
}

.contact input:focus,
.contact textarea:focus {
  border-bottom: 1px solid var(--primary-color); /* Cambia de color en focus */
}

.contact textarea {
  resize: none;
  min-height: 100px;
}

.contact .lexmake-button {
  background-color: var(--primary-color);
  color: white;
}

.contact .lexmake-button:hover {
  color: var(--primary-color);
  background-color: rgba(0, 0, 255, 0.1);
}

.contact__info {
  color: black;
  display: flex;
  align-items: center;
  gap: 30px;
  flex-direction: column;
  margin-top: 70px;
  text-align: center;
}

.contact__info-email {
  color: black;
  font-size: 20px;
}

.contact__social {
  display: flex;
  justify-content: center;
  gap: 36px;
}

.contact__social a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
}

.contact__social svg {
  height: 25px;
  width: 25px;
}

.contact__map-iframe {
  width: 100%;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.contact__map-iframe:hover {
  filter: grayscale(0%);
}
