.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f6f3eb;
  text-align: center;
}

.not-found h1 {
  font-size: 6rem;
  margin: 0;
  color: var(--primary-color);
}

.not-found__message {
  font-size: 1.2rem;
  margin: 1em 0 2em;
}

.not-found__link {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.not-found__link:hover {
  opacity: 0.8;
}

@media (min-width: 768px) {
  .not-found h1 {
    font-size: 8rem;
  }

  .not-found__message {
    font-size: 1.5rem;
  }
}
