.project-detail {
  margin: 1em;
  color: black;
  max-width: 800px;
}

.project-detail__title {
  font-size: 3em;
}

.project-detail__front-image {
  height: 320px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 2em;
}

.project-detail__content {
  margin-bottom: 2em;
}

.project-detail__section-header {
  margin-bottom: 1.5em;
  width: 100%;
}

.project-detail__header-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0.5em;
}

.project-detail__header-content h2 {
  font-family: RunwayBlend;
  font-size: 1.5em;
  margin: 0;
}

.project-detail__year {
  text-align: right;
}

.project-detail__line {
  height: 1px;
  background-color: black;
  width: 100%;
}

.project-detail__two-columns {
  display: grid;
  gap: 2em;
}

.project-detail__what-we-did {
  margin-top: 2em;
}

.project-detail__what-we-did h3,
.project-detail__categories h3 {
  margin-bottom: 1em;
  font-size: 1em;
  font-weight: bold;
}

.project-detail__categories ul,
.project-detail__what-we-did ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.project-detail__categories li {
  margin-bottom: 0.5em;
  color: #666;
}

.project-detail__what-we-did li {
  margin-bottom: 0.5em;
}

.project-detail__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Layout para desktop */
@media (min-width: 768px) {
  .project-detail {
    margin: 2em auto;
  }

  .project-detail__two-columns {
    grid-template-columns: 2fr 1fr;
    gap: 4em;
  }

  .project-detail__images {
    display: grid;
    gap: 1rem;
    grid-template-columns: 65% 35%;
    grid-template-rows: auto;
  }

  .image-container:nth-child(n + 3) {
    grid-column: 1 / -1;
  }

  .project-detail__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
  }

  .project-detail__modal img {
    max-width: 80%;
    max-height: 80vh;
    object-fit: contain;
    cursor: default;
  }

  .project-detail__image {
    cursor: pointer;
  }

  .project-detail__modal-image {
    max-width: 80%;
    max-height: 80vh;
    cursor: pointer;
  }
}
