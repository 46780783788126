/* Estilos del footer */
.footer {
  color: black;
  padding: 20px;
  text-align: center;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer__links {
  display: flex;
  gap: 20px;
}

.footer__links a {
  color: black;
  text-decoration: none;
}

.footer__links a:hover {
  text-decoration: underline;
}
